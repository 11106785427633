import React, { Fragment } from "react"
import Helmet from "../components/Helmet"
import Header from "../components/Header"
//import One from "../landingPage/One"
//import Two from "../landingPage/Two"
import Footer from "../components/Footer"

const LandingPage = () => (
  <Fragment>
    <Helmet
      title="Sample Report"
      descr="Enter your name and email address in the following form and we will send you a sample ETF Stock Report to view the breakdown weightings for selected portfolio sizes."
      slug="/landing"
    />
    <Header />
    {/*<One />
    <Two />*/}
    <Footer />
  </Fragment>
)

export default LandingPage
